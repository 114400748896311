import './scss/app.scss';
import {initChat} from "./js/chat";
import './js/sentry';


// -----------------------------------------------------------------------------------------*/
// jQuery 3.6.0 ----------------------------------------------------------------------------*/
// -----------------------------------------------------------------------------------------*/
global.$ = global.jQuery = require('jquery');


// -----------------------------------------------------------------------------------------*/
// jQuery validator ------------------------------------------------------------------------*/
// -----------------------------------------------------------------------------------------*/
import('./js/validation');

var fired = false;
$(document).ready(function() {
    $('input').on('focus', function() {
        if (fired === false) {
            var scriptElement = document.createElement('script');
            scriptElement.src = 'https://www.google.com/recaptcha/api.js';
            scriptElement.async = true;
            $('body').append(scriptElement);
            fired = true;
        }
    });
});


// -----------------------------------------------------------------------------------------*/
// Bootstrap 5.3 ---------------------------------------------------------------------------*/
// -----------------------------------------------------------------------------------------*/
require('bootstrap/js/dist/popover');
const bootstrap = require('bootstrap/dist/js/bootstrap.min');

initChat(bootstrap);