import {createHtmlFromString, setTimeoutPromise, supportStream, whenDocReady} from "./utils";
import markdownit from 'markdown-it'
import * as Sentry from "@sentry/browser";

const md = markdownit()

const textDecodder = new TextDecoder();

const PAGE_SELECTOR = ".page-chat";
const INPUT_SELECTOR = "#form-writing";
const DIALOG_BOX_SELECTOR = `${PAGE_SELECTOR} .dialogue-box`;
const SEND_BUTTON_SELECTOR = `#form-send`;
const QUESTIONS_BOX_SELECTOR = `#offcanvasQuestions`;
const QUESTIONS_SELECTOR = `${QUESTIONS_BOX_SELECTOR} .offcanvas-body button`;


const messageAccueil = `${process.env.PCB_MESSAGE_D_ACCUEIL}`
let PCB_BOT_MODE = `${process.env.PCB_BOT_MODE ?? 'auto'}`
if (PCB_BOT_MODE === 'auto') {
	PCB_BOT_MODE = supportStream() ? 'stream' : 'default';
}

let bootstrap
/**@var {HTMLElement} page */
let page;
/**@var {HTMLInputElement} input */
let input;
/**@var {HTMLTextAreaElement} dialogBox */
let dialogBox;
/**@var {HTMLButtonElement} sendButton */
let sendButton;

export function initChat(_bootstrap) {
	whenDocReady(init);
	bootstrap = _bootstrap;
}

const chat = {
	sessionId: undefined,
	inProgress: false,
	history: [],
};

function init() {
	page = document.querySelector(PAGE_SELECTOR);
	dialogBox = page?.querySelector(DIALOG_BOX_SELECTOR);
	input = document.querySelector(INPUT_SELECTOR);
	sendButton = document.querySelector(SEND_BUTTON_SELECTOR);
	if (!page || !input || !dialogBox || !sendButton) {
		return;
	}
	input.addEventListener('keydown', handleKeyDown);
	sendButton.addEventListener('click', sendMessage);

	initQuestionsSamples();
	if (!['', 'null', 'undefined'].includes(messageAccueil ?? '')) {
		sendRequest({message: `${process.env.PCB_MESSAGE_D_ACCUEIL}`});
	}
}


function initQuestionsSamples() {
	const questions = document.querySelectorAll(QUESTIONS_SELECTOR);
	questions.forEach(question => {
		question.addEventListener('click', handleQuestionClick);
	});
}

function handleQuestionClick(event) {
	const question = event.currentTarget;
	const message = question.textContent.trim();
	const messageData = addMessage('user', message);
	// sendRequest(messageData);
	sendRequest(messageData);
	bootstrap.Offcanvas.getOrCreateInstance(document.querySelector(QUESTIONS_BOX_SELECTOR)).hide();
	// input.focus();
	// input.value = message;
}

function clearDialog(force = false) {
	if (!force) {
		dialogBox.querySelector("#intro-chat")?.remove();
	} else {
		dialogBox.innerHTML = "";
	}
}

function clearInput() {
	input.value = "";
}

function handleKeyDown(event) {
	if (event.key === "Enter") {
		event.preventDefault();
		sendMessage();
	}
}

function sendMessage() {
	const message = input.value.trim();
	if (message === "") {
		return;
	}
	const messageData = addMessage('user', message);
	clearInput();
	// sendRequest(messageData);
	sendRequest(messageData);
}

function sendRequest(messageData) {

	if (isLoading()) {
		return;
	}
	setLoading();
	if (PCB_BOT_MODE === 'stream') {
		sendStreamRequest(messageData);
	} else {
		sendDefaultRequest(messageData);
	}
}
function sendDefaultRequest(messageData) {
	const loadingMessage = addMessage('loading', '...');
	makeRequestToServer(messageData)
		.finally(
			() => {
				removeMessage(loadingMessage.id);
				setLoading(false);
			}
		)
		.then(handleResponse)
		.then(handleSuccessResponse.bind(null, messageData))
		.catch(handleErrorResponse.bind(null, messageData))
}

function sendStreamRequest(messageData) {
	const loadingMessage = addMessage('loading', '...');
	makeStreamRequestToServer(messageData)
		.finally(
			() => {
				removeMessage(loadingMessage.id);
				setLoading(false);
			}
		)
		.then(handleStreamResponse.bind(null, messageData))
		// .then(handleResponse)
		// .then(handleSuccessResponse.bind(null, messageData))
		.catch(handleErrorResponse.bind(null, messageData))
}

function removeMessage(id, removeElement = true) {
	const index = chat.history.findIndex(message => message.id === id);
	if (index === -1) {
		return;
	}
	chat.history.splice(index, 1);
	if (removeElement) {
		const element = dialogBox.querySelector(`#${id}`);
		element?.remove();
	}
}

function makeRequestToServer(messageData) {
	return fetch(
		`${process.env.PCB_BOT_URL}/api-public/gpt/simpleChatAssistant2`
		, {
			method: 'POST',
			mode: 'cors',
			headers: {
				'Content-Type': 'application/json',
				// 'Origin': 'http://localhost',
			},
			body: JSON.stringify({
				sessionId: chat.sessionId,
				message: messageData.message,
			}),
		})
}

function makeStreamRequestToServer(messageData) {
	return fetch(
		`${process.env.PCB_BOT_URL}/api-public/gpt/simpleChatAssistantStream`
		, {
			method: 'POST',
			mode: 'cors',
			headers: {
				'Content-Type': 'application/json',
				// 'Origin': 'http://localhost',
			},
			body: JSON.stringify({
				sessionId: chat.sessionId,
				message: messageData.message,
			}),
		})
}

/**
 *
 * @param response
 */
async function handleResponse(response) {
	let data;
	try {
		data = await response.json();
	} catch (ex) {
		// debugger;
		throw new Error(`Un imprévu est survenu lors de la lecture de la réponse du serveur.

Veuillez réessayer plus tard ou de contacter Petit Coeur de Beurre pour signaler le problème si celui-ci persiste.

[téléphone: 06 24 50 56 99, email: contact@petitcoeurdebeurre.fr].
`);
	}

	if (data.status === 'error') {
		throw new Error(data.error);
	}

	return data.data;
}

function handleSuccessResponse(messageData, data) {
	if (data.sessionId && !chat.sessionId) {
		chat.sessionId = data.sessionId;
	}
	addMessage('bot', data.response);
}

async function handleStreamResponse(messageData, /** @var Response response*/response) {
	let messageElement = null;

	const responseStatus = response.status;
	console.error({responseStatus});
	if (responseStatus !== 200) {
		if (responseStatus === 504) {
			Sentry.captureMessage("Timeout error");
			addMessage(
				'error',
				md.render(`Il semble que je soit actuellement très solicité, veuillez réessayer dans une minute ou contactez Petit Coeur de Beurre pour signaler le problème si celui-ci persiste.

[téléphone: 06 24 50 56 99, email: contact@petitcoeurdebeurre.fr].`)
			);
			return;
		}

		throw new Error(`Un imprévu est survenu lors de la lecture de la réponse du serveur.

Veuillez réessayer plus tard ou de contacter Petit Coeur de Beurre pour signaler le problème si celui-ci persiste.

[téléphone: 06 24 50 56 99, email: contact@petitcoeurdebeurre.fr]`);
	}
	const reader = response
		.body
		.pipeThrough(new TextDecoderStream())
		.getReader();
	Sentry.captureMessage("reader.start");
	while (true) {
		await setTimeoutPromise(5);
		const {done, value} = await reader.read();

		if(value) {
			if (!messageElement) {
				messageElement = addMessage('bot', processChunk(value));
				setLoadingMessage(messageElement, true);
			} else {
				updateMessage(messageElement.id, processChunk(value));
			}
		}

		if (done) {
			Sentry.captureMessage("reader.done");
			break;
		}
	}

	const newSessionId = response.headers.get('Data-Session-Id')
	if (newSessionId !== !chat.sessionId) {
		chat.sessionId = newSessionId;
	}

	setLoadingMessage(messageElement, false);
}

function processChunk(chunk) {
	if (!chunk.match(/\r\n/)) {
		return chunk;
	}

	chunk = chunk.replace(/^\w*\r\n/, '');
	chunk = chunk.replace(/\r\n$/, '');

	chunk = chunk.replaceAll(/\r\n(\S*)\r\n/g, '');
	return chunk;

}

function setLoadingMessage(message, loading = true) {
	const messageElement = dialogBox.querySelector(`#${message?.id}`);
	if (!messageElement) {
		return;
	}
	if (loading && !messageElement.classList.contains('loading')) {
		messageElement.classList.add('loading');
		messageElement.querySelector('.bubble')
			.appendChild(
				createHtmlFromString('<img class="loading-img gif-chat" src="/images/anime-chat-dialogue.gif" alt="Chat" height="14" width="35">')
			);
	} else if (!loading && messageElement.classList.contains('loading')) {
		messageElement.classList.remove('loading');
		messageElement.querySelector('.loading-img')?.remove();
	}
}

function updateMessage(id, message) {
	const messageData = chat.history.find(message => message.id === id);
	if (!messageData) {
		return;
	}
	messageData.message += message;
	const messageElement = dialogBox.querySelector(`#${id}`);
	if (!messageElement) {
		return;
	}

	const cleanedMessage = messageData.message.replaceAll(/【\d+:\d+†[^】]+】/g, '');
	messageElement.querySelector('p').innerHTML = md.render(cleanedMessage);
}

function handleErrorResponse(messageData, error) {
	// debugger;
	const errorMessage = `
Un imprévu est survenu lors de la lecture de la réponse du serveur.


Veuillez réessayer plus tard ou de contacter Petit Coeur de Beurre pour signaler le problème si celui-ci persiste.

[téléphone: 06 24 50 56 99, email: contact@petitcoeurdebeurre.fr].
`
	Sentry.captureException(error, {data: {messageData, errorMessage}});
	addMessage(
		'error',
		errorMessage
	);
}

function createUserMessageElement(message) {
	return createHtmlFromString(`
		<div class="dialogue-user" id="${message.id}">
			<div class="bubble">
				<p>${message.message}</p>
			</div>
		</div>
	`);
}

function createBotMessageElement(message) {
	return createHtmlFromString(`
		<div class="dialogue-bot" id="${message.id}">
			<div class="bubble">
				<p>${message.message}</p>
			</div>
		</div>
	`);
}

function createLoadingMessageElement(message) {
	return createHtmlFromString(`
		<div class="dialogue-bot" id="${message.id}">
			<div class="bubble">
				<img class="gif-chat" src="/images/anime-chat-dialogue.gif" alt="Chat" height="14" width="35">
			</div>
		</div>
	`);
}

function createErrorMessageElement(message) {
	return createHtmlFromString(`
		<div class="dialogue-bot dialogue-bot--error" id="${message.id}">
			<div class="bubble">
				<p>${message.message}</p>
			</div>
		</div>
	`);
}


function generateMessageId() {
	return 'message-' + crypto.randomUUID();
}

function scrollToBottom() {
	dialogBox.scrollTop = dialogBox.scrollHeight;
}

function scrollToTop() {
	dialogBox.scrollTop = 0;
}

function addMessage(type, message, display = true) {
	if (chat.history.length === 0) {
		clearDialog(true);
	}
	const messageData = {
		id: generateMessageId(),
		type,
		message,
		status: 'ok',
	}
	chat.history.push(messageData);
	if (display) {
		displayMessage(messageData.id);
	}
	return messageData;
}

function displayMessage(id) {
	const messageData = chat.history.find(message => message.id === id);
	if (!messageData) {
		return;
	}
	const existingMessageElement = dialogBox.querySelector(`#${id}`);
	if (existingMessageElement) {
		return;
	}

	const messageElement = createMessageElement(messageData);
	dialogBox.appendChild(messageElement);
	scrollToBottom();
}

// function updateUI(clearInputField = false) {
// 	if (chat.history.length === 0) {
// 		scrollToTop();
// 		return;
// 	}
// 	if (clearInputField) {
// 		clearInput();
// 	}
// 	clearDialog();
// 	chat.history.forEach(message => {
// 		const messageElement = createMessageElement(message);
// 		dialogBox.appendChild(messageElement);
// 	});
// 	scrollToBottom();
// }

function createMessageElement(message) {
	switch (message.type) {
		case 'user':
			return createUserMessageElement(message);
		case 'bot':
			return createBotMessageElement(message);
		case 'error':
			return createErrorMessageElement(message);
		case 'loading':
			return createLoadingMessageElement(message);
		default:
			return createHtmlFromString('');
	}
}

function isLoading() {
	return chat.inProgress;
}

function setLoading(loading = true) {
	chat.inProgress = loading;

	updateUI();
}

function updateUI() {
	if (isLoading()) {
		input.disabled = true;
		sendButton.disabled = true;
	} else {
		input.disabled = false;
		sendButton.disabled = false;
	}
}