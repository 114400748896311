import * as Sentry from "@sentry/browser";

if (process.env.NODE_ENV === 'production') {
	Sentry.init({
		dsn: `${process.env.PCB_SENTRY_DSN}`,

		// Alternatively, use `process.env.npm_package_version` for a dynamic release version
		// if your build tool supports it.
		release: "1.0.0",
		integrations: [
			Sentry.browserTracingIntegration(),
			Sentry.replayIntegration(),
		],

		// Set tracesSampleRate to 1.0 to capture 100%
		// of transactions for tracing.
		// We recommend adjusting this value in production
		tracesSampleRate: 1.0, // TODO reduce

		// Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
		tracePropagationTargets: ["localhost", /^https:\/\/robert.petitcoeurdebeurre.fr/],

		// Capture Replay for 10% of all sessions,
		// plus for 100% of sessions with an error
		replaysSessionSampleRate: 0.1,
		replaysOnErrorSampleRate: 1.0,
	})
}
;